import React from "react"
import styled from "styled-components"
import colors from "shared/utils/colors"

const Container = styled.div`
  background: ${colors.grayBackground};
  padding: 2rem;
  border-left: 4px solid ${props => props.theme.colors.error};
  margin-top: 2rem;

  h3 {
    font-size: 1.8rem
    margin: 0; padding: 0;
    margin-bottom: 1rem
  }
  p, li { margin: 0; padding: 0; font-size: 1.5rem; }
  ul { margin-bottom: 0; }

  a {
    color: ${colors.primary};
  }
`

const PasswordErrorWell = () => {
  return (
    <Container>
      <h3>Your password is too weak</h3>
      <p>
        The password you tried to set isn't secure. Please ensure that the
        password you're trying to set:
      </p>
      <ul>
        <li>Is at least eight (8) characters in length.</li>
        <li>
          Does not contain the first part of your email address, your username
          or name.
        </li>
        <li>
          Is not a commonly set password. See{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/danielmiessler/SecLists/blob/master/Passwords/Common-Credentials/10k-most-common.txt">
            this list
          </a>{" "}
          of the most 10,000 most common passwords that we match against.
        </li>
      </ul>
    </Container>
  )
}
export default PasswordErrorWell
